/* CSS to support old "articles" in legacyArticles.json */

/*Thirds Gallery*/
.gallery-container {
  text-align: center;
}

.gallery-box {
  display: inline-block;
  vertical-align: top;
  width: 30%;
  margin: 2px;
  max-height: 300px;
  overflow: auto;
}
