@import 'styles/_colors.scss';

.ConnectFour {
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: min-content auto;
}

.ConnectFour__board-container {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.ConnectFour__board-container--not-legal {
  cursor: default;
}

.ConnectFour__selection-area {
  background-color: $secondary-one;
}

.ConnectFour__board-area {
  background-color: $primary-one;
}

.ConnectFour__move-value {
  text-align: center;
  color: $light-one;
}

.ConnectFour__square {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnectFour__square--column-hovered-legal {
  filter: brightness(110%);
}

.ConnectFour__square--column-hovered-not-legal {
  filter: brightness(90%);
}

.ConnectFour__circle {
  width: 80%;
  height: 80%;
  border-radius: 50%;
}

.ConnectFour__circle--empty {
  background-color: $secondary-one;
}

.ConnectFour__circle--p1 {
  background-color: $light-one;
}

.ConnectFour__circle--p2 {
  background-color: $dark-one;
}

.ConnectFour__circle--floating-coin {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.ConnectFour__overlay {
  color: $light-one;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(239, 239, 239, 0.2);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConnectFour__overlay--hidden {
  display: none;
}

.ConnectFour__loader,
.ConnectFour__loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.ConnectFour__loader {
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ConnectFour__loader 1.1s infinite linear;
  animation: ConnectFour__loader 1.1s infinite linear;
  z-index: 100;
}
@-webkit-keyframes ConnectFour__loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ConnectFour__loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
